<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17">
    <path fill="#FFF" d="M5.582 16.789s-5.415.021-5.415-4.62c0-4.642 4.816-5.907 4.816-9.51 0-.99-.264-1.425-.831-1.992 2.559.078 4.595 2.65 4.595 6.301 0 .726-.264 2.228-.58 2.545-.317.316 2.057-1.437 2.057-2.967 0 0 1.622 1.543 1.622 4.88 0 3.338-3.578 5.703-5.278 5.703 1.067-1.068 1.793-2.083 1.793-3.283 0-2.422-2.03-2.211-2.532-3.445-.034-.083-.059-.17-.074-.258-.047-.328.036-.662.232-.93l.553-.797s-3.152.907-3.152 4.282c0 3.376 1.68 3.574 2.194 4.091" transform="translate(-698 -1495) translate(503 1243) translate(0 50) translate(190 196) translate(5.5 5.5)"/>
  </svg>
</template>

<script>
  export default {
    name: "fire"
  }
</script>