<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12">
    <path fill="#FFF" d="M0 10.204L3.061 10.204 3.061 7.143 0 7.143zM5.102 10.204L8.163 10.204 8.163 4.082 5.102 4.082z" transform="translate(-598 -1498) translate(503 1243) translate(0 50) translate(90 195) translate(5 8) translate(.333 2.667)"/>
    <path fill="#777" d="M10.204 10.204L13.265 10.204 13.265 0 10.204 0z" transform="translate(-598 -1498) translate(503 1243) translate(0 50) translate(90 195) translate(5 8) translate(.333 2.667)"/>
  </svg>
</template>

<script>
  export default {
    name: "level"
  }
</script>