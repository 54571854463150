<template>
  <div class="workout-item">
    <router-link :to="{ name: 'workout-detail', params:{id:$root.getLink(workoutItem)}}" class="workout-item-link">
      <div class="workout-item-image">
        <img :src="workoutItem.media.general[0].image" alt="" v-if="workoutItem.media.general">
      </div>
      <div class="workout-item-top">
        <div class="d-flex justify-content-between align-items-center">
          <div class="workout-item-flag-wrapper">
            <div class="workout-item-flag d-flex justify-content-center align-items-center" v-if="!!workoutItem.is_premium && workoutItem.is_premium === '1'">
              <div class="workout-item-flag-icon d-flex justify-content-center align-items-center d-lg-inline-block">
                <lock2/>
              </div>
              <p class="d-none d-lg-block">PREMIUM</p>
            </div>
          </div>
          <button type="button" class="workout-item-bookmark" :class="[ workoutItem.bookmarked ? 'active' : '']" v-on="workoutItem.bookmarked == true ? { click: removeBookmark } : { click: addBookmark }">
            <bookmark/>
          </button>
        </div>
      </div>
      <div class="workout-item-content">
        <p class="workout-item-title">{{ workoutItem.title }}</p>
        <div class="workout-item-tags">
          <div class="workout-item-tag-item d-flex justify-content-center align-items-center" v-if="!!workoutItem.duration && workoutItem.duration != ''">
            <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
              <clock/>
            </div>
            <p>{{ workoutItem.duration }}</p>
          </div>
          <div class="workout-item-tag-item d-flex justify-content-center align-items-center" v-if="!!workoutItem.itensity && workoutItem.itensity != ''">
            <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
              <level/>
            </div>
            <p>{{ workoutItem.itensity }}</p>
          </div>
          <div class="workout-item-tag-item d-flex justify-content-center align-items-center" v-if="!!workoutItem.level && workoutItem.level != ''">
            <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
              <fire/>
            </div>
            <p>{{ workoutItem.level }}</p>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import bookmark from "@/components/svg/bookmark.vue";
import lock2 from "@/components/svg/lock2.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "workout-item",
  data() {
    return {
      errors: [],
      bookmarkActive: false,
    }
  },
  components : {
    bookmark,
    lock2,
    clock,
    level,
    fire
  },
  props: {
    workoutItem: {
      required: true,
    },
  },
  methods: {
    addBookmark(e) {
      e.preventDefault();

      let authKey = window.$cookies.get("authKey");

      const requestBody = {
        related_id: this.workoutItem.id,
        related_type: 0,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'bookmark', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.success) {
              this.workoutItem.bookmarked = true;

              let bookmark = {
                "id": this.workoutItem.id,
                "title": this.workoutItem.title,
                "level": this.workoutItem.level,
                "duration": this.workoutItem.duration,
                "slug": this.workoutItem.slug
              };

              this.$root.workoutBookmarks.push(bookmark);
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    },
    removeBookmark(e) {
      e.preventDefault();

      let authKey = window.$cookies.get("authKey");

      const requestBody = {
        bookmark: this.workoutItem.id,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'bookmark', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.success) {
              this.workoutItem.bookmarked = false;
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    },
  },
}
</script>