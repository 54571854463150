<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
    <path fill="#FFF" d="M8.358 2.5c.324 0 .588.263.588.589 0 .324-.264.588-.588.588h-.963c.007.036.01.072.01.11l.001.773c1.13.102 2.178.496 3.067 1.105l.432-.434-.459-.459c-.23-.23-.23-.603 0-.833.23-.23.602-.23.832 0l.871.871.006.006.004.005.88.88c.23.229.23.602 0 .831-.115.116-.265.173-.416.173-.151 0-.301-.057-.416-.173l-.469-.468-.354.355c1.183 1.175 1.916 2.802 1.916 4.598 0 3.574-2.908 6.483-6.483 6.483-3.576 0-6.484-2.909-6.484-6.483 0-3.377 2.594-6.158 5.894-6.457l.001-.773c0-.038.004-.074.01-.11h-.963c-.324 0-.587-.264-.587-.588 0-.326.263-.589.587-.589h3.083zM6.817 5.71c-2.926 0-5.306 2.38-5.306 5.307 0 2.925 2.38 5.305 5.306 5.305 2.925 0 5.306-2.38 5.306-5.305 0-2.927-2.38-5.307-5.306-5.307zm0 7.81c.324 0 .588.264.588.589v1.282c0 .325-.264.59-.588.59-.325 0-.589-.265-.589-.59V14.11c0-.325.264-.589.589-.589zM4.233 7.618L6.28 9.665c.166-.067.347-.103.537-.103.801 0 1.453.652 1.453 1.454 0 .803-.652 1.455-1.453 1.455-.802 0-1.455-.652-1.455-1.455 0-.18.033-.352.093-.51L3.401 8.45c-.23-.23-.23-.602 0-.832.23-.23.602-.23.832 0zm-.602 2.81c.325 0 .589.263.589.588 0 .325-.264.59-.589.59H2.349c-.325 0-.589-.265-.589-.59 0-.325.264-.588.59-.588H3.63zm7.7 0c.325 0 .59.263.59.588 0 .325-.265.59-.59.59H10.05c-.326 0-.589-.265-.589-.59 0-.325.263-.588.589-.588h1.282zm-4.514.311c-.153 0-.278.125-.278.277 0 .153.125.278.278.278.153 0 .277-.125.277-.278 0-.152-.124-.277-.277-.277zm0-4.73c.324 0 .588.263.588.589V7.88c0 .325-.264.589-.588.589-.325 0-.589-.264-.589-.589V6.598c0-.326.264-.59.589-.59z" transform="translate(-521 -1496) translate(503 1243) translate(0 50) translate(13 195) translate(5.5 5.5)"/>
  </svg>
</template>

<script>
  export default {
    name: "clock"
  }
</script>